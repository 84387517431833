import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  DragDropContext,
  Draggable,
  DraggableProvidedDragHandleProps,
  DraggingStyle,
  Droppable,
  DropResult,
  NotDraggingStyle,
} from 'react-beautiful-dnd';
import { RootState } from '../../store';
import { AssignmentRubric, Comment, Rating, Rubric, RubricTemplate } from '../../types/types';
import Button from '../core/button/Button/Button';
import Icon from '../core/display/Icon';
import SearchBar from '../core/input/SearchBar/SearchBar';
import LoadingSpinner from '../core/layout/LoadingSpinner/LoadingSpinner';
import CommentPrompt from './CommentPrompt';
import RatingPrompt from './RatingPrompt';
import _ from 'lodash';
import { convertUTCDateToLocalDate, setPageTitle, storageAvailable } from '../../utils/functions';
import AssignmentPhaseIcon from '../core/display/AssignmentPhaseIcon/AssignmentPhaseIcon';
import { Tooltip, TooltipContent, TooltipTrigger } from '../core/layout/Tooltip/Tooltip';
import Dropdown from '../core/button/Dropdown/Dropdown';

interface Props {
  rubric: RubricTemplate | AssignmentRubric;
  onFavoriteToggle?: () => void;
  onImport?: (rubric: RubricTemplate) => void;
  onRename: (name: string) => void;
  onReorder: (prompts: Rubric) => void;
  onVisibilityToggle?: (visible: boolean) => void;
  readOnly?: boolean;
  visible?: boolean;
}

function RubricEditorPage({
  rubric,
  onFavoriteToggle,
  onImport,
  onRename,
  onReorder,
  onVisibilityToggle,
  readOnly = false,
  visible = true,
}: Props): JSX.Element {
  useEffect(
    () =>
      setPageTitle(
        `${(rubric as AssignmentRubric).assignmentRubricId ? 'Assignment Rubric' : 'Rubric Template'}: "${
          rubric?.name
        }"`,
      ),
    [rubric],
  );
  const { assignmentId } = useParams() as { assignmentId?: string };
  const user = useSelector((state: RootState) => state.user);
  const location = useLocation();
  const navigate = useNavigate();

  const baseUrl = useMemo(() => {
    let path = location.pathname;
    if (path.charAt(path.length - 1) === '/') path = path.substring(0, path.length - 1);
    return path;
  }, [location]);

  const [searchValue, setSearchValue] = useState('');
  const [rubricName, setRubricName] = useState(rubric?.name);

  const filteredRubricItems = useMemo(
    () =>
      rubric.items.filter(
        (prompt) =>
          (prompt.hasOwnProperty('commentId') ? (prompt as Comment).commentName : (prompt as Rating).name)
            .toLocaleLowerCase()
            .indexOf(searchValue.toLocaleLowerCase()) !== -1,
      ),
    [rubric.items, searchValue],
  );

  const onEditComment = useCallback(
    (comment: Comment) => {
      if (storageAvailable('sessionStorage'))
        window.sessionStorage.setItem(`comment-${comment.commentId}`, JSON.stringify(comment));
      navigate(`${baseUrl}/edit/comment/${comment.commentId}`);
    },
    [navigate, baseUrl],
  );

  const onEditRating = useCallback(
    (rating: Rating) => {
      if (storageAvailable('sessionStorage'))
        window.sessionStorage.setItem(`rating-${rating.ratingId}`, JSON.stringify(rating));
      navigate(`${baseUrl}/edit/rating/${rating.ratingId}`);
    },
    [navigate, baseUrl],
  );

  useEffect(() => {
    if (rubric && rubric.name !== rubricName && rubricName !== '' && rubricName !== undefined) onRename(rubricName);
  }, [rubricName, rubric, onRename]);

  const fromImport = assignmentId !== undefined && onImport !== undefined;

  if (rubric && !(!visible && user.courseRole === 'STUDENT')) {
    const userOwned =
      (rubric.hasOwnProperty('user')
        ? user.userId === (rubric as RubricTemplate).user.userId
        : user.courseRole !== 'STUDENT') || user.role === 'ADMIN';
    return (
      <div id="rubric-editor-page" className="page">
        <div className={`banner ${fromImport ? 'with-import' : ''}`}>
          <h1 className="sr-only">{rubricName}</h1>
          <label className="sr-only" htmlFor="editable-rubric-name">
            Rubric Name
          </label>
          <input
            name="rubricName"
            id="editable-rubric-name"
            type="text"
            value={rubricName}
            onChange={(e) => setRubricName(e.target.value)}
            disabled={!userOwned}
          />
          <div className="details-row">
            <div className="main-section">
              {userOwned && onFavoriteToggle ? (
                <Button className="favorite-btn" onClick={onFavoriteToggle} disabled={readOnly} classOverride>
                  <Icon
                    code={rubric.favorite ? 'star' : 'star_outline'}
                    color={rubric.favorite ? '#FFD23E' : '#919191'}
                  />
                </Button>
              ) : null}

              <span id="num-prompts">{rubric.items.length} Prompts</span>
              <span id="last-modified">Last Modified: {convertUTCDateToLocalDate(rubric.updatedAt)}</span>
              {visible !== undefined ? (
                <span
                  id="visibility-toggle"
                  className="button-mini"
                  aria-disabled={onVisibilityToggle === undefined || readOnly}
                >
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <input
                        id="visible"
                        name="visible"
                        type="checkbox"
                        checked={visible}
                        onChange={
                          onVisibilityToggle
                            ? (e: React.ChangeEvent<HTMLInputElement>) => onVisibilityToggle(e.target.checked)
                            : undefined
                        }
                        disabled={onVisibilityToggle === undefined || readOnly}
                      />
                    </TooltipTrigger>
                    <TooltipContent>{`${visible ? 'Visible to' : 'Hidden from'} students`}</TooltipContent>
                  </Tooltip>
                  <label htmlFor="visible">
                    <Icon code={visible ? 'visibility' : 'visibility_off'} ariaHidden />
                    <span className="sr-only">Visible</span>
                  </label>
                </span>
              ) : null}
            </div>
            <SearchBar
              placeholder="Search Prompts"
              value={searchValue}
              setValue={setSearchValue}
              resultsLength={filteredRubricItems.length}
            />
            {userOwned && !readOnly ? (
              <Dropdown
                className="peer-button button-rad button-low"
                id="add-prompt-dropdown"
                buttonContent="Add Prompt"
                iconCode="add"
                align="left"
                top="100%"
              >
                <Dropdown.Link className="comment" href={`${baseUrl}/new/comment`} route>
                  New Comment Prompt
                </Dropdown.Link>
                {rubric.target !== 'REFLECT' ? (
                  <Dropdown.Link className="rating" href={`${baseUrl}/new/rating`} route>
                    New Rating Prompt
                  </Dropdown.Link>
                ) : null}
                <Dropdown.Link className="comment" href={`${baseUrl}/copy/comment`} route>
                  Copy Existing Comment Prompt
                </Dropdown.Link>
                {rubric.target !== 'REFLECT' ? (
                  <Dropdown.Link className="rating" href={`${baseUrl}/copy/rating`} route>
                    Copy Existing Rating Prompt
                  </Dropdown.Link>
                ) : null}
              </Dropdown>
            ) : null}
          </div>

          {fromImport ? (
            <Button
              id="import-btn"
              variant="rad low"
              onClick={onImport ? () => onImport(rubric as RubricTemplate) : undefined}
            >
              Import this Rubric
            </Button>
          ) : null}
        </div>
        <div id="prompts-container">
          {readOnly || !userOwned || searchValue !== '' ? (
            filteredRubricItems.map((prompt) => {
              const type = prompt.hasOwnProperty('commentId') ? 'COMMENT' : 'RATING';
              switch (type) {
                case 'COMMENT':
                  const commentPrompt = prompt as Comment;
                  return (
                    <CommentPrompt
                      key={commentPrompt.commentId}
                      comment={commentPrompt}
                      onEdit={userOwned && !readOnly ? onEditComment : undefined}
                    />
                  );
                case 'RATING':
                  const ratingPrompt = prompt as Rating;
                  return (
                    <RatingPrompt
                      key={ratingPrompt.ratingId}
                      rating={ratingPrompt}
                      onEdit={userOwned && !readOnly ? onEditRating : undefined}
                    />
                  );
              }
            })
          ) : (
            <PromptList
              baseUrl={baseUrl}
              rubric={rubric}
              onEditComment={onEditComment}
              onEditRating={onEditRating}
              onReorder={onReorder}
            />
          )}
        </div>
      </div>
    );
  }

  if (!visible && user.courseRole === 'STUDENT') {
    return (
      <div id="rubric-editor-page" className="page">
        <div className="panel" id="rubric-hidden-text">
          <h1>Rubric Unavailable</h1>
          <p>
            This rubric will be available after the <AssignmentPhaseIcon size={24} phase="submit" />{' '}
            <b>Submission Phase</b>.
          </p>
        </div>
      </div>
    );
  }

  return <LoadingSpinner />;
}

interface PromptListProps {
  baseUrl: string;
  rubric: RubricTemplate | AssignmentRubric;
  onEditComment: (comment: Comment) => void;
  onEditRating: (rating: Rating) => void;
  onReorder: (prompts: Rubric) => void;
}

function PromptList({ baseUrl, rubric, onEditComment, onEditRating, onReorder }: PromptListProps): JSX.Element {
  const { assignmentId, rubricTemplateId } = useParams() as { assignmentId?: string; rubricTemplateId?: string };
  const [prompts, setPrompts] = useState<Rubric>(rubric.items);
  const [reorderQueued, setReorderQueued] = useState(false);

  useEffect(() => {
    setPrompts(rubric.items);
  }, [rubric]);

  useEffect(() => {
    if (reorderQueued) {
      onReorder(prompts);
      setReorderQueued(false);
    }
  }, [onReorder, prompts, reorderQueued]);

  const reorder = useCallback((startIndex: number, endIndex: number) => {
    setPrompts((prevPrompts) => {
      const result = _.cloneDeep(prevPrompts);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
      return result.map((item, i) => ({ ...item, order: i }));
    });
    setReorderQueued(true);
  }, []);

  const onDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination) return;
      reorder(result.source.index, result.destination.index);
    },
    [reorder],
  );

  const handleReorder = useCallback(
    (i: number, diff: number) => {
      const newIndex = i + diff;
      if (newIndex >= 0 && newIndex < prompts.length) reorder(i, newIndex);
    },
    [prompts.length, reorder],
  );

  const customControls = (index: number, dragHandleProps?: DraggableProvidedDragHandleProps) => (
    <>
      <span className="drag-handle" {...dragHandleProps} aria-hidden tabIndex={-1}>
        <Icon code="drag_handle" />
      </span>
      <Button className="button-mini" classOverride onClick={() => handleReorder(index, -1)}>
        <Icon code="arrow_drop_up" label="Reorder up" />
      </Button>
      <Button className="button-mini" classOverride onClick={() => handleReorder(index, 1)}>
        <Icon code="arrow_drop_down" label="Reorder down" />
      </Button>
    </>
  );

  return (
    <div className="page" id="rubric-row">
      {prompts.length < 1 ? (
        <div className="empty-interface">
          <h1>Build a Rubric</h1>
          <p style={{ maxWidth: '560px' }}>
            We recommend using both comment prompts and rating prompts. You must have at least 3 rating prompts for
            valid and reliable grading.
          </p>

          <div className="choice-wrapper">
            <p>
              To get started, <b>select &quot;Add a Prompt&quot;</b> above
            </p>
            {assignmentId && rubricTemplateId === undefined ? (
              <>
                <p className="or">Or</p>
                <Button id="import-btn" variant="rad low" href={`${baseUrl}/rubrics/library`} route>
                  Import from Rubric Library
                </Button>
              </>
            ) : null}
          </div>
        </div>
      ) : (
        <div id="rubric-builder-interface">
          <div className="rubric-container">
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {prompts.map((prompt, i) => {
                      if (prompt.hasOwnProperty('commentId')) {
                        const commentPrompt = prompt as Comment;
                        return (
                          <Draggable key={commentPrompt.commentId} draggableId={commentPrompt.commentId} index={i}>
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                              >
                                <CommentPrompt
                                  key={commentPrompt.commentId}
                                  comment={commentPrompt}
                                  onEdit={onEditComment}
                                  customControls={customControls(i, provided.dragHandleProps ?? undefined)}
                                />
                              </div>
                            )}
                          </Draggable>
                        );
                      } else if (prompt.hasOwnProperty('ratingId')) {
                        const ratingPrompt = prompt as Rating;
                        return (
                          <Draggable key={ratingPrompt.ratingId} draggableId={ratingPrompt.ratingId} index={i}>
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                              >
                                <RatingPrompt
                                  key={ratingPrompt.ratingId}
                                  rating={ratingPrompt}
                                  onEdit={onEditRating}
                                  customControls={customControls(i, provided.dragHandleProps ?? undefined)}
                                />
                              </div>
                            )}
                          </Draggable>
                        );
                      }
                      return <>Error</>;
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </div>
      )}
    </div>
  );
}

export const getItemStyle = (isDragging: boolean, draggableStyle: DraggingStyle | NotDraggingStyle | undefined) => {
  return {
    padding: 2,
    ...draggableStyle,
  };
};

export const getListStyle = (isDraggingOver: boolean) => {
  return {
    padding: 4,
    borderRadius: 4,
    backgroundColor: isDraggingOver ? 'lightBlue' : undefined,
  };
};
export const customControls = (
  index: number,
  handleReorder: (i: number, diff: number) => void,
  dragHandleProps?: DraggableProvidedDragHandleProps,
) => (
  <>
    <span className="drag-handle" {...dragHandleProps}>
      <Icon code="drag_handle" />
    </span>
    <Button className="button-mini" classOverride onClick={() => handleReorder(index, -1)}>
      <Icon code="arrow_drop_up" label="Reorder up" />
    </Button>
    <Button className="button-mini" classOverride onClick={() => handleReorder(index, 1)}>
      <Icon code="arrow_drop_down" label="Reorder down" />
    </Button>
  </>
);

export default RubricEditorPage;
