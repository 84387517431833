import React, { useCallback, useEffect, useState } from 'react';
import { openModal, useModalContext } from '../../contexts/ModalContext';
import { Comment, Tag } from '../../types/types';
import { setPageTitle } from '../../utils/functions';
import AlertBar from '../core/display/AlertBar';
import Icon from '../core/display/Icon';
import RichEditor from '../core/editor/RichEditor/RichEditor';
import SingleForm from '../core/input/Form/SingleForm';
import TagsInput from '../core/input/TagsInput/TagsInput';
import Button from '../core/button/Button/Button';
import Toggle from '../core/input/Toggle/Toggle';
const { Row, Col } = SingleForm;

interface Props {
  fromLibrary?: boolean;
  initPrompt?: Comment;
  requestCreateComment: (commentData: Comment, successCb: () => void, errorCb: () => void) => void;
  requestDeleteCommentPrompt: (commentId: string, successCb: () => void) => void;
  requestUpdateComment: (commentData: Comment, successCb: () => void, errorCb: () => void) => void;
  returnToRubricEditor: () => void;
  type: 'new' | 'edit';
}

function CommentPromptEditor({
  fromLibrary = false,
  initPrompt,
  requestCreateComment,
  requestDeleteCommentPrompt,
  requestUpdateComment,
  returnToRubricEditor,
  type,
}: Props): JSX.Element {
  useEffect(() => setPageTitle('Edit Comment Prompt'), []);

  const [commentName, setCommentName] = useState(initPrompt ? initPrompt.commentName : '');
  const [commentPrompt, setCommentPrompt] = useState(initPrompt ? initPrompt.commentPrompt : '');
  const [requiredComments, setRequiredComments] = useState(initPrompt ? initPrompt.minimumComments : 1);
  const [optionalComments, setOptionalComments] = useState(
    initPrompt ? initPrompt.maximumComments - initPrompt.minimumComments : 0,
  );
  const [tags, setTags] = useState<Tag[]>(initPrompt ? initPrompt.tags : []);
  const attachmentExists = initPrompt ? initPrompt.attachmentExists : false;
  const [hiddenOnResults, setHiddenOnResults] = useState<boolean>(
    initPrompt ? initPrompt.hiddenOnResults ?? false : false,
  );

  const [advancedSettingsOpen, setAdvancedSettingsOpen] = useState(false);

  const { modalDispatch } = useModalContext();

  const save = useCallback(
    (successCb: () => void, errorCb: () => void) => {
      const commentData: Comment = {
        assignmentId: initPrompt ? initPrompt.assignmentId : '',
        commentId: initPrompt ? initPrompt.commentId : '',
        commentName,
        commentPrompt,
        minimumComments: requiredComments,
        maximumComments: requiredComments + optionalComments,
        order: initPrompt ? initPrompt.order : -1,
        tags,
        target: 'SUBMISSION',
        attachmentExists,
        hiddenOnResults,
      };

      if (type === 'edit') requestUpdateComment(commentData, successCb, errorCb);
      else if (type === 'new') requestCreateComment(commentData, successCb, errorCb);
    },
    [
      initPrompt,
      commentName,
      commentPrompt,
      requiredComments,
      optionalComments,
      tags,
      attachmentExists,
      hiddenOnResults,
      type,
      requestUpdateComment,
      requestCreateComment,
    ],
  );

  const onSubmit = useCallback(
    (formData: FormData, callback: () => void) => {
      save(returnToRubricEditor, callback);
    },
    [returnToRubricEditor, save],
  );

  return (
    <div className="page" id="comment-prompt-editor">
      <SingleForm
        className="comment-prompt-form"
        title={`${(type === 'new' && 'New') || (type === 'edit' && 'Edit')} Comment Prompt`}
        submitText="Save"
        onSubmit={onSubmit}
        backButton
        buttonPosition="both"
        customCtrls={
          initPrompt && type === 'edit' ? (
            <button
              className="button-mini delete-btn"
              type="button"
              onClick={() => {
                modalDispatch(
                  openModal({
                    heading: 'Delete Comment Prompt',
                    label: 'Are you sure you want to delete this comment prompt?',
                    buttonText: 'Delete',
                    onConfirm: () => {
                      requestDeleteCommentPrompt(initPrompt.commentId, returnToRubricEditor);
                    },
                    children: fromLibrary ? (
                      <AlertBar>
                        Warning: Deleting this prompt will also remove it from any library rubrics that include it
                      </AlertBar>
                    ) : undefined,
                  }),
                );
              }}
            >
              <Icon code="delete" />
            </button>
          ) : undefined
        }
      >
        <Row>
          <Col flexBasis="100%">
            <label className="sr-only" htmlFor="comment-name">
              Comment Name:
            </label>
            <input
              id="comment-name"
              type="text"
              placeholder="Comment name"
              value={commentName}
              onChange={(e) => {
                setCommentName(e.target.value);
              }}
            />

            <label>Comment Prompt:</label>
            <RichEditor initContent={commentPrompt} onChange={setCommentPrompt} />

            <div className="flex-row justify-center">
              <div className="flex-col justify-between">
                <label id="req-comments-lbl" htmlFor="req-comments">
                  Required comments:
                </label>
                <label id="opt-comments-lbl" htmlFor="opt-comments">
                  Optional comments:
                </label>
              </div>
              <div className="flex-col justify-evenly">
                <input
                  id="req-comments"
                  type="number"
                  value={requiredComments}
                  onChange={(e) => {
                    setRequiredComments(parseInt(e.target.value));
                  }}
                  min="1"
                  max="9"
                  required
                />
                <input
                  id="opt-comments"
                  type="number"
                  value={optionalComments}
                  onChange={(e) => {
                    setOptionalComments(parseInt(e.target.value));
                  }}
                  min="0"
                  max="9"
                  required
                />
              </div>
            </div>

            <label htmlFor="tags">Tags:</label>
            <TagsInput id="tags" defaultTags={tags} onChange={setTags} />

            <Button
              className="advanced-btn"
              variant="link low"
              type="button"
              onClick={() => setAdvancedSettingsOpen((prevState) => !prevState)}
            >
              <Icon code={advancedSettingsOpen ? 'expand_more' : 'chevron_right'} />
              Advanced Settings
            </Button>
            <div className="advanced-container" style={advancedSettingsOpen ? undefined : { display: 'none' }}>
              <Toggle checked={!hiddenOnResults} onChange={(e) => setHiddenOnResults(!e.target.checked)}>
                Show on Student Results
              </Toggle>
            </div>
          </Col>
        </Row>
      </SingleForm>
    </div>
  );
}

export default CommentPromptEditor;
