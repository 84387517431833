import React from 'react';
import { useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { RootState } from '../../../../store';
import Avatar from '../../display/Avatar/Avatar';
import Icon from '../../display/Icon';

export const homePaths = [
  '/course',
  '/course/dashboard',
  '/course/templates',
  '/assignments',
  '/assignments/all',
  '/assignments/templates',
  '/rubrics',
  '/rubrics/library',
  '/rubrics/comments',
  '/rubrics/ratings',
  '/rubrics/tags',
  '/rubrics/course/ratings',
  '/users',
  '/admin/purchasing/management',
];

function HomeBanner(): JSX.Element {
  const user = useSelector((state: RootState) => state.user);

  return (
    <div id="home-banner" aria-label="Home Navigation" role="navigation">
      <div className="banner-content">
        <Link
          id="profile-preview"
          to="/profile"
          aria-label={`Profile Link for ${user.name} (${user.role.toLowerCase()})`}
          role="button"
        >
          <Avatar user={user} size={56} />
          <div className="details">
            <div className="name">{user.name}</div>
            <div className="role">{user.role.toLowerCase()}</div>
          </div>
        </Link>

        <div className="home-links" aria-label="Home Links">
          <NavLink to="/course" className={({ isActive }) => (isActive ? 'selected' : '')}>
            <Icon code="library_books" ariaHidden />
            Courses
          </NavLink>
          {user.admin || user.schoolAdmin ? (
            <NavLink to="/assignments" className={({ isActive }) => (isActive ? 'selected' : '')}>
              <Icon code="assignment" ariaHidden />
              Assignments
            </NavLink>
          ) : null}
          <NavLink to="/rubrics" className={({ isActive }) => (isActive ? 'selected' : '')}>
            <Icon code="content_paste" ariaHidden />
            Rubrics
          </NavLink>
          {user.admin || user.schoolAdmin ? (
            <NavLink to="/users" className={({ isActive }) => (isActive ? 'selected' : '')}>
              <Icon code="people" ariaHidden />
              Users
            </NavLink>
          ) : null}
          {user.admin ? (
            <NavLink to="/contract" className={({ isActive }) => (isActive ? 'selected' : '')}>
              <Icon code="inventory" ariaHidden />
              Access Management
            </NavLink>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default HomeBanner;
